.image-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  perspective: 1000px;  /* Adds depth to the 3D transformations */
}

.image-row a, .image-row .line {
  display: inline-block;
  text-decoration: none;
  transition: transform 1.5s ease, background-color 0.5s ease; /* Smooth transitions */
  transform-style: preserve-3d; /* Ensures children participate in the 3D space */
}

.image-row img {
  width: 100px;  /* Adjust size as needed */
  height: auto;
  backface-visibility: hidden; /* Improves visual clarity during rotation */
}

.image-row a:hover, .image-row .line:hover {
  transform: rotateY(360deg); /* Rotate on hover */
}

.image-row .line:hover {
  background-color: #ff0000; /* Change line color on hover */
}

.line {
  height: 2px;
  background-color: #000;
  flex-grow: 1;
  margin: 0 10px; /* Adjust spacing between images and lines */
  transform-origin: center; /* Rotation origin */
}

/* Media query for devices with a max width of 600px (common smartphone breakpoint) */
@media (max-width: 600px) {
  .image-row {
    flex-direction: column; /* Stack images vertically */
  }

  .line {
    width: 2px;  /* Change line orientation */
    height: 50px; /* Adjust vertical spacing */
    flex-grow: 0; /* Prevent line from growing */
    margin: 10px auto; /* Center lines vertically */
    transform-origin: center top; /* Adjust rotation origin for vertical lines */
  }
}



