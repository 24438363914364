.contact-info {
  background-color: rgb(164, 230, 245);  /* Updated background color */
  padding: 20px;
  margin: 20px auto;  /* Auto margins for horizontal centering */
  border-radius: 8px;
  box-shadow: 0 0px 0px rgba(0,0,0,0.1);
  width: 80%;  /* Adjust width as needed, or use a fixed width like 300px */
  max-width: 500px;  /* Ensures that it does not grow too large on wider screens */
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center-align items horizontally in the flex container */
  justify-content: center;  /* Center-align items vertically in the flex container */
  text-align: center;  /* Ensures that text is centered inside each block */
}

.contact-info h2, .contact-info p {
  width: 100%;  /* Ensures full width for proper centering of text */
  margin: 10px 0;  /* Adds vertical spacing */
  color: #333;
}

.contact-info a {
  color: #333;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}


